// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/node/app/site/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/home/node/app/site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/home/node/app/site/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/home/node/app/site/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-comp-manager-comp-manager-js": () => import("/home/node/app/site/src/pages/comp_manager/comp_manager.js" /* webpackChunkName: "component---src-pages-comp-manager-comp-manager-js" */),
  "component---src-pages-contact-js": () => import("/home/node/app/site/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/home/node/app/site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ios-development-js": () => import("/home/node/app/site/src/pages/ios_development.js" /* webpackChunkName: "component---src-pages-ios-development-js" */),
  "component---src-pages-my-habits-my-habits-faq-js": () => import("/home/node/app/site/src/pages/my_habits/my_habits_faq.js" /* webpackChunkName: "component---src-pages-my-habits-my-habits-faq-js" */),
  "component---src-pages-my-habits-my-habits-news-js": () => import("/home/node/app/site/src/pages/my_habits/my_habits_news.js" /* webpackChunkName: "component---src-pages-my-habits-my-habits-news-js" */),
  "component---src-pages-my-habits-my-habits-privacy-js": () => import("/home/node/app/site/src/pages/my_habits/my_habits_privacy.js" /* webpackChunkName: "component---src-pages-my-habits-my-habits-privacy-js" */),
  "component---src-pages-my-habits-my-habits-terms-js": () => import("/home/node/app/site/src/pages/my_habits/my_habits_terms.js" /* webpackChunkName: "component---src-pages-my-habits-my-habits-terms-js" */),
  "component---src-pages-my-habits-my-habits-user-guide-js": () => import("/home/node/app/site/src/pages/my_habits/my_habits_user_guide.js" /* webpackChunkName: "component---src-pages-my-habits-my-habits-user-guide-js" */),
  "component---src-pages-my-habits-my-habits-js": () => import("/home/node/app/site/src/pages/my_habits/my_habits.js" /* webpackChunkName: "component---src-pages-my-habits-my-habits-js" */),
  "component---src-pages-pain-logger-pain-logger-faq-js": () => import("/home/node/app/site/src/pages/pain_logger/pain_logger_faq.js" /* webpackChunkName: "component---src-pages-pain-logger-pain-logger-faq-js" */),
  "component---src-pages-pain-logger-pain-logger-news-js": () => import("/home/node/app/site/src/pages/pain_logger/pain_logger_news.js" /* webpackChunkName: "component---src-pages-pain-logger-pain-logger-news-js" */),
  "component---src-pages-pain-logger-pain-logger-press-js": () => import("/home/node/app/site/src/pages/pain_logger/pain_logger_press.js" /* webpackChunkName: "component---src-pages-pain-logger-pain-logger-press-js" */),
  "component---src-pages-pain-logger-pain-logger-privacy-js": () => import("/home/node/app/site/src/pages/pain_logger/pain_logger_privacy.js" /* webpackChunkName: "component---src-pages-pain-logger-pain-logger-privacy-js" */),
  "component---src-pages-pain-logger-pain-logger-user-guide-js": () => import("/home/node/app/site/src/pages/pain_logger/pain_logger_user_guide.js" /* webpackChunkName: "component---src-pages-pain-logger-pain-logger-user-guide-js" */),
  "component---src-pages-pain-logger-pain-logger-js": () => import("/home/node/app/site/src/pages/pain_logger/pain_logger.js" /* webpackChunkName: "component---src-pages-pain-logger-pain-logger-js" */),
  "component---src-pages-privacy-js": () => import("/home/node/app/site/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-server-solutions-js": () => import("/home/node/app/site/src/pages/server_solutions.js" /* webpackChunkName: "component---src-pages-server-solutions-js" */),
  "component---src-pages-services-js": () => import("/home/node/app/site/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-solutions-js": () => import("/home/node/app/site/src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-terms-js": () => import("/home/node/app/site/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-web-solutions-js": () => import("/home/node/app/site/src/pages/web_solutions.js" /* webpackChunkName: "component---src-pages-web-solutions-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/node/app/site/.cache/data.json")

